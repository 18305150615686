export default {
  auth(to, from, next) {
    let KeyJwt = localStorage.getItem('jwt')

    fetch('https://api-uol.grupocobz.com.br/login/userValidate', {
      method: 'POST',
      body: JSON.stringify({
        jwt: KeyJwt
      })
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == 'Acesso negado' || typeof KeyJwt == 'undefined') {
          next('/admin-login')
        }
      })
    next()
  },
  authCliente(to, from, next) {
    if (window.location.protocol !== 'https:')
      window.location.href = 'https://pagseguro.grupocobz.com.br/login'
    let myToken = localStorage.getItem('_my_token')

    fetch(`https://api-uol.grupocobz.com.br/login/clientValidate`, {
      method: 'POST',
      body: JSON.stringify({
        jwt: myToken
      })
    })
      .then(response => response.json())
      .then(json => {
        if (json.error === 'Acesso negado' || typeof myToken == 'undefined') {
          next('/login')
        }
      })
    next()
  }
}
