<template>
  <div class="body">
    <div><slot /></div>
  </div>
</template>

<script>
export default {
  name: "LayoutCliente",
  data() {
    return {};
  },
};
</script>

<style scoped>
.body {
  width: 100%;

  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .body {
    height: auto;
  }
}
</style>