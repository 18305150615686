import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Guard from '../services/middleware'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: Guard.authCliente
  },
  /* Rotas Admin */

  {
    path: '/admin-login',
    name: 'Login',
    component: () => import('../views/Admin/LoginAdmin.vue')
  },
  {
    path: '/admin-filtro',
    name: 'Filtro',
    /*  beforeEnter: Guard.auth, */
    component: () => import('../views/Admin/FiltroAdmin.vue')
  },
  {
    path: '/admin-dashboard',
    name: 'Dashboard',
    /*    beforeEnter: Guard.auth, */
    component: () => import('../views/Admin/DashboardAdmin.vue')
  },
  {
    path: '/admin-logout',
    name: 'Logout',
    component: () => import('../views/Admin/LogoutAdmin.vue')
  },
  /* Rotas CLientes */
  {
    path: '/confirmacao',
    name: 'Confirmacao',
    /*  beforeEnter: Guard.authCliente, */
    component: () => import('../views/Cliente/ConfirmacaoCliente.vue')
  },
  {
    path: '/contraproposta',
    name: 'Contraproposta',
    /* beforeEnter: Guard.authCliente, */
    component: () => import('../views/Cliente/ContrapropostaCliente.vue')
  },
  {
    path: '/propostaencaminhada',
    name: 'PropostaEncaminhada',
    component: () => import('../views/Cliente/PropostaEncaminhadaCliente.vue')
  },
  {
    path: '/propostaenviada',
    name: 'PropostaEnviada',
    component: () => import('../views/Cliente/PropostaEnviadaCliente.vue')
  },
  {
    path: '/propostas',
    name: 'Propostas',
    /* beforeEnter: Guard.authCliente, */
    component: () => import('../views/Cliente/PropostasCliente.vue')
  },
  {
    path: '/tempoexpirado',
    name: 'TempoExpirado',
    component: () => import('../views/Cliente/TempoExpiradoCliente.vue')
  },
  {
    path: '/login',
    name: 'LoginCliente',
    /* beforeEnter: Guard.authCliente, */
    component: () => import('../views/Cliente/LoginCliente.vue')
  },

  /* Rota Erro 404 */
  {
    path: '*',
    name: 'notFound',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
