<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.v-application--wrap {
  min-height: 0vh !important;
  background-color: #e5e5e5 !important;
}
body {
  width: 100%;
  height: 100vh;
  background-color: #e5e5e5;
}
</style>