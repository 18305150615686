<template>
  <!--  <div>
    <h1>Bem Vindo</h1>
  </div> -->

  <LayoutCliente />
</template>

<script>
import LayoutCliente from "@/Layouts/LayoutCliente.vue";
export default {
  name: "Home",
  components: {
    LayoutCliente,
  },
};
</script>

<style scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
</style>
