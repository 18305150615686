import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
/* import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts) */
// register directive v-money and component <money>
Vue.use(money)

Vue.use(VueTheMask)

Vue.config.productionTip = false

Vue.prototype.$url = 'https://api-uol.grupocobz.com.br'

/* http://192.168.0.147/acordoonline_uol */
/* https://api-uol.grupocobz.com.br */

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
